import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import Hero from "../components/Hero"
import Services from "../components/Services"
import Testimonies from "../components/Testimonies"
import CallToAction from "../components/CallToAction"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      description="Mestre reiki, taróloga, terapeuta floral e praticante de Barras de Access Consciousness. Estou pronta para te dar a mão na sua trajetória de descoberta de seu protagonismo na sua vida."
    />
    <Hero />
    <Services />
    <Testimonies />
    <CallToAction />
  </Layout>
)

export default IndexPage
