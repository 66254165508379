import React from "react"

const CallToAction = () => (
  <section className="text-gray-700 body-font">
    <div className="container px-5 pt-6 pb-24 mx-auto">
      <div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
        <h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-900">
          Está pronto para entrar nessa jornada de autoconhecimento comigo?
          Vamos juntos nessa!
        </h1>
        <a
          href="http://wa.me/5551993093330"
          target="_blank"
          className="flex-shrink-0 text-white bg-purple-500 border-0 py-2 px-8 focus:outline-none hover:bg-purple-600 rounded text-lg mt-10 mx-auto"
        >
          Quero Mudar
        </a>
      </div>
    </div>
  </section>
)

export default CallToAction
