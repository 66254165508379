const testimonies = [
  {
    text:
      "Poder contar com uma profissional e um ser humano do gabarito da Gabi nessa tão sonhada busca de desenvolvimento pessoal e autoconhecimento é mais que um privilégio, é um abençoado presente da vida.",
    name: "Laura Arce",
  },
  {
    text:
      "Eu achei as aulas muito boas para acalmar o espirito agitado do estresse, e depois continuar uma vida calma e alegre.  Muito bom pra todos que querem viver melhor.",
    name: "Eloisa Borgato",
  },
]

export default testimonies
