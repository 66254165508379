import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import AnchorLink from "react-anchor-link-smooth-scroll"

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "hero-image.JPG" }) {
        childImageSharp {
          fluid(maxWidth: 720, maxHeight: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <section id="sobre" className="text-gray-700 body-font">
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            Gabriela Muttoni
          </h1>
          <p className="mb-8 leading-relaxed">
            Mestre reiki, taróloga, terapeuta floral e praticante de Barras de
            Access Consciousness. Estou pronta para te dar a mão na sua
            trajetória de descoberta de seu protagonismo na sua vida.
          </p>
          <div className="flex justify-center flex-col md:flex-row">
            <AnchorLink
              href="#atendimentos"
              className="inline-flex text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 rounded text-lg"
            >
              Conheça meu trabalho
            </AnchorLink>
            <a
              href="http://wa.me/5551993093330"
              target="_blank"
              className="ml-0 mt-2 md:ml-4 md:mt-0 inline-flex text-gray-700 bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded text-lg"
            >
              Marcar atendimento
            </a>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <Img
            fluid={data.file.childImageSharp.fluid}
            className="rounded"
            style={{ boxShadow: "7px 7px #9f7aea" }}
          />
        </div>
      </div>
    </section>
  )
}

export default Hero
