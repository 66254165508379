import React from "react"

import SwiperCore, { Autoplay, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"

import Testimony from "../Testimony"
import testimonies from "./data"

SwiperCore.use([Autoplay, Pagination])

const Testimonies = () => {
  return (
    <section id="depoimentos" className="text-gray-700 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-4 ">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            Depoimentos
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            Uma das melhores sensações como terapeuta integrativa é sentir que
            está fazendo a diferença, e nada como um depoimento para reforçar
            essa sensação.
          </p>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-purple-500 inline-flex"></div>
          </div>
        </div>
        <Swiper
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {testimonies.map(({ text, name, role }) => {
            return (
              <SwiperSlide>
                <Testimony text={text} name={name} role={role} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </section>
  )
}

export default Testimonies
