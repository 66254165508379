import React from "react"
import { FaBrain, FaSpa, FaSun } from "react-icons/fa"
import AnchorLink from "react-anchor-link-smooth-scroll"

const Services = () => {
  return (
    <section id="atendimentos" className="text-gray-700 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-20 ">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            Minhas especialidades
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            Escolhi essas ferramentas que facilitam o indivíduo a ver a ligação
            entre ele e o que ele deseja para si mesmo. Emoção, razão, intuição
            e escolha: o que há em comum entre elas? O indivíduo e sua
            experiência única e singular neste mundo.
          </p>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-purple-500 inline-flex"></div>
          </div>
        </div>
        <div className="flex flex-wrap mb-16  ">
          <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-purple-100 text-purple-500 mb-5 flex-shrink-0">
              <FaSpa size={26} />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Florais Alkhemy Lab
              </h2>
              <p className="leading-relaxed text-base">
                Desenvolvidos usando técnicas alquímicas aplicadas às plantas
                desde o plantio em forma de mandalas até a produção no
                laboratório. Assim, agem não só nos sintomas dos desequilíbrios,
                mas na sua origem, promovendo a cura e o bem-estar.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-purple-100 text-purple-500 mb-5 flex-shrink-0">
              <FaSun size={26} />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Tarot Alquímico
              </h2>
              <p className="leading-relaxed text-base">
                Com os arcanos maiores (arquétipos), facilita uma visão geral
                das 12 principais áreas da vida através da mandala astrológica,
                revelando um cenário bem preciso e abrangente, onde vemos quais
                são as nossas facilidades e desafios no momento.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-purple-100 text-purple-500 mb-5 flex-shrink-0">
              <FaBrain size={26} />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Barras de Access
              </h2>
              <p className="leading-relaxed text-base">
                É um processo terapêutico transformador que funciona através de
                toques suaves em 32 pontos na cabeça que se conectam com
                diferentes aspectos de nossa vida, liberando registros negativos
                de pensamentos, sentimentos e emoções.
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center flex-col mx-12 md:mx-0 md:flex-row">
          <AnchorLink
            href="#depoimentos"
            className="inline-flex text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 rounded text-lg"
          >
            Ver Depoimentos
          </AnchorLink>
          <a
            href="http://wa.me/5551993093330"
            target="_blank"
            className="ml-0 mt-2 md:ml-4 md:mt-0 inline-flex text-gray-700 bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded text-lg"
          >
            Marcar atendimento
          </a>
        </div>
      </div>
    </section>
  )
}

export default Services
